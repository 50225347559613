import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ContattiPage = () => (
    <>
        <div className="banner">
            <StaticImage src="../images/dog-ambulatorio.jpg" className="h-96 mt-20 object-cover" />
        </div>
        <Layout>
            <Seo title="Contatti" description="Ambulatorio Veterinario a Pisa" />
            <div className="">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="">
                            <a href="tel:+393925307631" className="btn btn-primary">Urgenze: +39 392 530 7631</a>
                            <h3 className="leading-6">Contattaci come preferisci</h3>
                            <p className="mt-1 text-gray-600">Se ci contatti attraverso il form ricordati di utilizzare un'indirizzo email valido dove protremo rispondere alla tua richiesta.</p>
                        </div>
                        <div className="mb-12">
                            <h4>Ambulatorio Veterinario Loni Marcucci Lami</h4>
                            <p>Via Cisanello, 163 – 56124 Pisa<br></br>Telefono: 050 575001<br></br>info@ambulatorioveterinariopisa.it</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="formContatto">
                            <form method="POST" name="Contatto dal sito" data-netlify="true" netlify-honeypot="bot-field" action="../thankyou">
                            <input type="hidden" name="form-name" value="Contatto dal sito" />
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                
                                                <p class="hidden">
                                                    <label>
                                                        Don’t fill this out if you’re human: <input name="bot-field" />
                                                    </label>
                                                </p>
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Nome <span className="text-red-500">*</span>
                                                </label>
                                                <input
                                                    required
                                                    type="text"
                                                    name="nome"
                                                    id="nome"
                                                    autoComplete="given-name"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Email <span className="text-red-500">*</span>
                                                </label>
                                                <input
                                                    required
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    autoComplete="email"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>


                                            <div className="col-span-6">
                                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                                    Il tuo messaggio <span className="text-red-500">*</span>
                                                </label>
                                                <textarea
                                                    required
                                                    name="messaggio"
                                                    id="messaggio"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                            </div>

                                        </div>
                                        <div className="mt-4 space-y-4">
                                            <div className="flex items-center">
                                                <input
                                                    required
                                                    id="trattamento-dati"
                                                    name="trattamento-dati"
                                                    type="checkbox"
                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label htmlFor="trattamento-dati" className="ml-3 block text-sm font-medium text-gray-700">
                                                    Autorizzo il trattamento dei dati personali come previsto <Link to="../privacy">dall'informativa privacy</Link> <span className="text-red-500">*</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-full border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Invia la richiesta
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>
)

export default ContattiPage